import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93')
];

export const server_loads = [0,17,2,19];

export const dictionary = {
		"/(website)": [~79,[17],[18]],
		"/(app)/_prototypes/action-plan": [37,[2]],
		"/(app)/_prototypes/maturity-assessment": [38],
		"/(app)/admin": [39,[2,6]],
		"/(app)/admin/library": [40,[2,6,7]],
		"/(app)/admin/library/emission-activities": [41,[2,6,7]],
		"/(app)/admin/library/initiatives": [42,[2,6,7]],
		"/(app)/admin/organisations": [43,[2,6]],
		"/(website)/appointments/[slug]": [~80,[17],[18]],
		"/(website)/blog": [~81,[17],[18]],
		"/(website)/blog/articles/[slug]": [~82,[17],[18]],
		"/(website)/company/_about-us": [84,[17],[18]],
		"/(website)/company/_careers": [85,[17],[18]],
		"/(website)/company/contact": [86,[17],[18]],
		"/(website)/company/subscription-terms": [87,[17],[18]],
		"/(website)/company/[slug]": [~83,[17],[18]],
		"/(backend)/healthz/liveness": [77],
		"/(backend)/healthz/readiness": [78],
		"/(website)/home/[slug]": [~88,[17],[18]],
		"/(app)/(auth)/invite/colleague": [20,[2]],
		"/(app)/(auth)/invite/email-sent": [21,[2]],
		"/(app)/(auth)/invite/not-found": [22,[2]],
		"/(app)/(auth)/invite/validate-otp": [23,[2]],
		"/(website)/legal/[slug]": [~89,[17],[18]],
		"/(website)/marketplace/[slug]": [~90,[17],[18]],
		"/(app)/(auth)/onboarding": [26,[2,4]],
		"/(app)/(auth)/onboarding/company-details": [27,[2,4]],
		"/(app)/(auth)/onboarding/invite-your-team": [28,[2,4]],
		"/(app)/(auth)/onboarding/your-profile": [29,[2,4]],
		"/(app)/(auth)/onboard": [~24,[2,3]],
		"/(app)/(auth)/onboard/organisation": [25,[2,3]],
		"/(app)/orgs": [44,[2,8]],
		"/(app)/orgs/[orgId]": [45,[2,8,9]],
		"/(app)/orgs/[orgId]/dashboards/demo": [46,[2,8,9]],
		"/(app)/orgs/[orgId]/emissions": [47,[2,8,9,10]],
		"/(app)/orgs/[orgId]/emissions/activity-data": [48,[2,8,9,10]],
		"/(app)/orgs/[orgId]/emissions/report": [49,[2,11]],
		"/(app)/orgs/[orgId]/onboard/finding-your-coach": [50,[2,8]],
		"/(app)/orgs/[orgId]/onboard/meet-your-coach": [51,[2,8]],
		"/(app)/orgs/[orgId]/onboard/user": [52,[2,8]],
		"/(app)/orgs/[orgId]/pathways/b-corp": [54,[2,8,9]],
		"/(app)/orgs/[orgId]/pathways/net-zero": [55,[2,8,9,12]],
		"/(app)/orgs/[orgId]/pathways/net-zero/roadmap": [56,[2,8,9,12]],
		"/(app)/orgs/[orgId]/pathways/net-zero/targets": [57,[2,8,9,12]],
		"/(app)/orgs/[orgId]/pathways/net-zero/targets/report": [58,[2,13]],
		"/(app)/orgs/[orgId]/pathways/ppn-carbon-reduction-plan": [59,[2,8,9,14]],
		"/(app)/orgs/[orgId]/pathways/ppn-carbon-reduction-plan/roadmap": [60,[2,8,9,14]],
		"/(app)/orgs/[orgId]/pathways/ppn-carbon-reduction-plan/targets": [61,[2,8,9,14]],
		"/(app)/orgs/[orgId]/pathways/[trackId]/prioritization": [53,[2,8,9]],
		"/(app)/orgs/[orgId]/plan": [62,[2,8,9,15]],
		"/(app)/orgs/[orgId]/policies": [63,[2,8,9]],
		"/(app)/orgs/[orgId]/policies/[slug]": [64,[2,8,9]],
		"/(app)/orgs/[orgId]/reporting/b-corp": [65,[2,8,9]],
		"/(app)/orgs/[orgId]/reporting/b-corp/generate": [66,[2,8,9]],
		"/(app)/orgs/[orgId]/reporting/b-corp/maturity": [67,[2,8,9]],
		"/(app)/orgs/[orgId]/reporting/our-story": [68,[2,8,9]],
		"/(app)/orgs/[orgId]/value-chain": [69,[2,8,9,16]],
		"/(app)/orgs/[orgId]/value-chain/downstream": [70,[2,8,9,16]],
		"/(app)/orgs/[orgId]/value-chain/products-and-services": [71,[2,8,9,16]],
		"/(app)/orgs/[orgId]/value-chain/upstream": [72,[2,8,9,16]],
		"/(website)/pricing/[slug]": [~91,[17],[18]],
		"/(app)/public/b-impact-assessment/[reportId]": [73,[2]],
		"/(app)/public/our-sustainability-story/[orgId]": [74,[2]],
		"/(app)/public/sustainability/initiatives": [75,[2]],
		"/(app)/public/sustainability/initiatives/[initiativeSlug]": [76,[2]],
		"/(app)/(auth)/session/error": [30,[2,5]],
		"/(app)/(auth)/session/expired-link": [31,[2,5]],
		"/(app)/(auth)/session/link-accounts": [32,[2,5]],
		"/(app)/(auth)/session/login": [33,[2,5]],
		"/(app)/(auth)/session/signin": [34,[2,5]],
		"/(app)/(auth)/session/signup": [35,[2,5]],
		"/(app)/(auth)/session/verify-email": [36,[2,5]],
		"/(website)/solutions/[slug]": [~92,[17],[18]],
		"/(website)/supply-chain/[slug]": [~93,[19]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';